<template>
  <div>
    <h2>Produktionsauftrag</h2>
    <div v-if="$apollo.queries.produktionsauftra_ge.loading">
      Lade Daten...
    </div>

    <div v-else>
      <div v-for="detail in produktionsauftra_ge" :key="detail.AuftragsNr">
        <p>Produktionsauftragsnr. {{ detail.AuftragsNr }}</p>
        <p>Noch offen: {{ detail.MengeNochOffen }}</p>
        <p>Artikel: {{ detail.Artikelcode }}</p>
        <p>Zu Auftragsbestätigung: {{ detail.zuBelegNr }}</p>

        <hr />

        <div v-if="!$apollo.queries.belege_positionen.loading">
          <div v-for="pos in belege_positionen" :key="pos.BelegNr">
            <p>Liefetermin: {{ pos.Liefertermin }}</p>
            <p>Liefetermin: {{ pos.Menge }}</p>
            <p>Liefetermin: {{ pos.Liefertermin }}</p>
            <p>Liefetermin: {{ pos.Liefertermin }}</p>
          </div>
        </div>

        <hr />

        <div v-if="!$apollo.queries.Artikel.loading">
          <div v-for="art in Artikel" :key="art.Artikelcode">
            <p>Bezeichnung: {{ art.Bezeichnung }}</p>
            <p>Formcode: {{ art.FormCode }}</p>
            <p>Formwerkzeug: {{ art.Formwerkzeug }}</p>
            <p>Rohlingshöhe: {{ art.Rohlingh_he }} mm</p>
            <p>Durchmesser: {{ parseInt(art.Durchmesser) }} mm</p>
            <p>Rohling: {{ art.Rohling }} mm</p>
            <p>Bohrung: {{ art.Bohrung }}</p>
            <p>Bindung: {{ art.Bindung }}</p>
            <p>Belaghöhe: {{ art.Belagh_he }} mm</p>
            <p>Belagbreite: {{ art.Belagbreite }} mm</p>
            <p>Abmessung: {{ art.Abmessung }}</p>
            <p>Gesamthöhe: {{ parseInt(art.Gesamth_he) }} mm</p>
            <p>Körnung: {{ art.K_rnung }}</p>
            <p>Konzentration: {{ art.Konzentration }}</p>
            <p>Korndichte: {{ art.Korndichte }}</p>
            <p>Korngröße: {{ art.Korngr_e }}</p>
            <p>Qualität: {{ art.Qualit_t }}</p>
            <p>Werkstoff: {{ art.Werkstoff }}</p>
            <p>Winkel: {{ art.Winkel || "-" }}°</p>

            <p class="font-bold underline">
              Du brauchst {{ art.Rohlingh_he * parseInt(detail.Menge) }} mm
              {{ art.Rohling }}er Aluminium. Zu bestellen wären
              {{
                calcAluBedarf(
                  art.Rohling,
                  art.Rohlingh_he * parseInt(detail.Menge)
                )
              }}
              kg
            </p>
            <p>{{ cheaper }}, dort ist es {{ howMuchCheaper }}€ günstiger.</p>
          </div>
        </div>

        <hr />

        <div v-if="!$apollo.queries.artikel_arbeitsga_nge.loading">
          <div v-for="ag in artikel_arbeitsga_nge" :key="ag.Artikelcode">
            <p>
              <b>Arbeitsgang: {{ ag.Arbeitsgang }}<br /></b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

const PA_DATA = gql`
  query Produktionsauftrag($id: Int! = 0) {
    produktionsauftra_ge(where: { AuftragsNr: { _eq: $id } }) {
      Artikelcode
      AuftragsNr
      MengeNochOffen
      MengeBearbeitet
      Menge
      zuBelegNr
      zuBelegart
    }
  }
`;

const BELEGE_DATA = gql`
  query BelegePositionen($id: String! = "0") {
    belege_positionen(
      where: { BelegNr: { _eq: $id }, Belegart: { _eq: "AB" } }
    ) {
      BelegNr
      Belegart
      BestellNr
      Bezeichnung
      Liefertermin
      LieferterminBerechnet
      LieferterminBest_tigt
      LieferterminBest_tigtDatum
      LieferterminDatum
      Menge
      Position
      Preis
      ProduktionsAuftragsNr
      Rohling
      Verkaufspreis
      Zeile
      f_rProdAuftrag
      f_rProdAuftragZeile
      zuBelegNr
      zuBelegart
      zuZeile
    }
  }
`;

const AG_DATA = gql`
  query Arbeitsgaenge($id: String! = "0") {
    artikel_arbeitsga_nge(where: { Artikelcode: { _eq: $id } }) {
      Arbeitsgang
    }
  }
`;

const PA_ARTIKEL = gql`
  query Artikel($artikelcode: String = "0") {
    Artikel(where: { Artikelcode: { _eq: $artikelcode } }) {
      Artikelcode
      Bezeichnung
      FormCode
      Formwerkzeug
      Rohlingh_he
      Durchmesser
      Rohling
      Bohrung
      Bindung
      Belagh_he
      Belagbreite
      Abmessung
      Gesamth_he
      K_rnung
      Konzentration
      Korndichte
      Korngr_e
      Qualit_t
      Werkstoff
      Winkel
    }
  }
`;

export default {
  data() {
    return {
      aluPreise: {
        40: {
          ess: 6.25,
          mefa: 6.4
        },
        80: {
          ess: 6.25,
          mefa: 6.4
        },
        105: {
          ess: 6.25,
          mefa: 6.4
        },
        130: {
          ess: 6.25,
          mefa: 6.4
        },
        160: {
          ess: 6.25,
          mefa: 6.4
        },
        180: {
          ess: 6.25,
          mefa: 6.4
        },
        210: {
          ess: 6.25,
          mefa: 6.4
        },
        260: {
          ess: 6.25,
          mefa: 6.4
        }
      },
      cheaper: "",
      hochMuchCheaper: ""
    };
  },
  methods: {
    calcAluBedarf(rohling, laenge) {
      const eins = rohling / 2 / 100;
      const zwei = eins * eins;
      const drei = laenge / 100;

      const ergebnis = Math.ceil(3.14 * zwei * drei * 2.85);

      const priceEss = Math.ceil(this.aluPreise[rohling].ess * ergebnis);
      const priceMefa = Math.ceil(this.aluPreise[rohling].mefa * ergebnis);
      this.cheaper =
        priceEss < priceMefa ? "Bei ESS bestellen" : "Bei Mefa bestellen";

      this.howMuchCheaper =
        priceEss < priceMefa ? priceMefa - priceEss : priceEss - priceMefa;

      // TODO: Losgrößen in Entscheidung mit einbeziehen
      // TODO: Mindestbestellwert für jeweilige Lieferanten mit einbeziehen
      // TODO: Entscheidung für Bestellung Splitten
      // TODO: Lieferkosten mit einberechnen

      return ergebnis;
    }
  },
  apollo: {
    produktionsauftra_ge: {
      query: PA_DATA,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    },
    Artikel: {
      query: PA_ARTIKEL,
      variables() {
        return {
          artikelcode: this.$apollo.data.produktionsauftra_ge[0].Artikelcode
        };
      }
    },
    belege_positionen: {
      query: BELEGE_DATA,
      variables() {
        return {
          id: this.$apollo.data.produktionsauftra_ge[0].zuBelegNr
        };
      }
    },
    artikel_arbeitsga_nge: {
      query: AG_DATA,
      variables() {
        return {
          id: this.$apollo.data.produktionsauftra_ge[0].Artikelcode
        };
      }
    }
  }
};
</script>

<style></style>
